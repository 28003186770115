<template>
  <Index>
    <div v-wechat-title="$route.meta.title"></div>
    <div class="main">
      <div class="title">
        留言板
      </div>
      <el-input
        v-model="leaveWordForm.message_contents"
        :rows="7"
        type="textarea"
        placeholder="说点什么吧..."
        class="textarea_input"
      />
      <div class="messageButton">
        <el-button
          v-if="$store.state.isLogin"
          type="primary"
          @click="insertLeaveWord()"
          class="message_button"
          :loading="leaveWordButtonLoading"
        >发布</el-button>
        <el-popover
          placement="left"
          v-else
        >
          <template #reference>
            <el-button
              type="primary"
              class="message_button"
            >发布</el-button>
          </template>
          <template #default>
            需要登录才能留言哦。^_~
          </template>
        </el-popover>
      </div>
      <div
        v-for="item in leaveWordInfo"
        :key="item.id"
      >
        <MessageShow
          :messageDate="item"
          :style="{'margin_left':'0px', 'margin_top':'24px'}"
          :father_id="item.id"
          scene="first"
          :submitDataFunction="insertThreadedLeaveWord"
          @getData="selectLeaveWord"
        >
          <!-- <template #avatar>
            <img :src="'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/'+item.user_avatar" />
          </template> -->
        </MessageShow>
        <div
          v-for="item_child in item.child_leave_word"
          :key="item_child.id"
        >
          <MessageShow
            :messageDate="item_child"
            :style="{'margin_left':'50px', 'margin_top':'16px'}"
            :father_id="item.id"
            scene="second"
            :submitDataFunction="insertThreadedLeaveWord"
            @getData="selectLeaveWord"
          >
          </MessageShow>
        </div>
      </div>
    </div>
  </Index>
</template>

<script>
// import { onMounted, reactive, ref } from '_vue@3.2.31@vue'
import { onMounted, reactive, ref } from '@vue/runtime-core'
import { INSERTLEAVEWORD, SELECTLEAVEWORD } from '../api/api'
import { ElMessage } from 'element-plus'
import { setCookie } from '../tools/cookie.ts'
import store from '../store/index'
import Index from '../components/Index.vue'
import MessageShow from '@/components/MessageShow.vue'
export default {
  components: {
    Index,
    MessageShow
  },
  setup() {
    onMounted(() => {
      selectLeaveWord()
    })
    // 新增留言表单
    const leaveWordForm = reactive({
      message_contents: '',
      leaveWord_id: 0
    })
    // 发布按钮等待状态
    const leaveWordButtonLoading = ref(false)
    // 发表留言
    const insertLeaveWord = () => {
      if (leaveWordForm.message_contents === '') {
        ElMessage({
          showClose: true,
          message: '你忘记填写留言了！',
          type: 'warning'
        })
        return
      }
      leaveWordButtonLoading.value = true
      INSERTLEAVEWORD(leaveWordForm).then((response) => {
        if (response.code === 0) {
          selectLeaveWord()
          leaveWordForm.message_contents = ''
          leaveWordButtonLoading.value = false
          ElMessage({
            showClose: true,
            message: '留言发布成功！',
            type: 'success'
          })
        } else if (response.code === -1) {
          ElMessage({
            showClose: true,
            message: response.msg,
            type: 'warning'
          })
          // 清空cookie
          setCookie('blogName', '', -1)
          setCookie('avatar', '', -1)
          setCookie('is_login', '', -1)
          // 更新vuex的状态
          store.commit('updateIsLogin', false)
          leaveWordButtonLoading.value = false
        }
      })
    }
    // 留言数据
    const leaveWordInfo = ref()
    // 文章总条数
    const leaveWordTotal = ref()
    // 当前页数
    const page = ref(1)
    // 每页展示条数
    const pageSize = ref(20)
    // 修改分页
    const changePage = (current_change) => {
      page.value = current_change
      selectLeaveWord()
    }
    // 获取留言
    const selectLeaveWord = () => {
      SELECTLEAVEWORD({ page: page.value, pageSize: pageSize.value }).then(
        (response) => {
          if (response.code === 0) {
            leaveWordInfo.value = response.data.data
            leaveWordTotal.value = response.data.total
          }
        }
      )
    }
    // 回复输入框展示id
    const threadedLeaveWordId = ref(0)
    // 回复评论内容
    const threadedLeaveWordFrom = reactive({
      message_contents: '',
      father_id: 0,
      leaveWord_id: 0
    })
    // 打开回复留言
    const clickThreadedLeaveWord = (activate_id, father_id) => {
      threadedLeaveWordFrom.message_contents = ''

      if (father_id === undefined) {
        threadedLeaveWordFrom.father_id = activate_id
      } else {
        threadedLeaveWordFrom.father_id = father_id
        threadedLeaveWordFrom.leaveWord_id = activate_id
      }

      if (threadedLeaveWordId.value === activate_id) {
        threadedLeaveWordId.value = 0
        threadedLeaveWordFrom.leaveWord_id = 0
      } else {
        threadedLeaveWordId.value = activate_id
      }
    }
    // 回复留言
    const insertThreadedLeaveWord = (
      father_id,
      leaveWord_id,
      message_contents
    ) => {
      return new Promise((resolve, reject) => {
        INSERTLEAVEWORD({
          father_id: father_id,
          leaveWord_id: leaveWord_id,
          message_contents: message_contents
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
      // if (threadedLeaveWordFrom.message_contents === '') {
      //   ElMessage({
      //     showClose: true,
      //     message: '你忘记填写回复了！',
      //     type: 'warning'
      //   })
      //   return
      // }
      // console.log(father_id, leaveWord_id, message_contents)
      // threadedLeaveWordButtonLoading.value = true
      // INSERTLEAVEWORD(threadedLeaveWordFrom)
      //   .then((response) => {
      //     if (response.code === 0) {
      //       selectLeaveWord()
      //       threadedLeaveWordFrom.message_contents = ''
      //       threadedLeaveWordFrom.leaveWord_id = 0
      //       threadedLeaveWordId.value = 0
      //       threadedLeaveWordButtonLoading.value = false
      //       ElMessage({
      //         showClose: true,
      //         message: '回复发表成功！',
      //         type: 'success'
      //       })
      //     } else if (response.code === -1) {
      //       ElMessage({
      //         showClose: true,
      //         message: response.msg,
      //         type: 'warning'
      //       })
      //       // 清空cookie
      //       setCookie('blogName', '', -1)
      //       setCookie('avatar', '', -1)
      //       setCookie('is_login', '', -1)
      //       // 更新vuex的状态
      //       store.commit('updateIsLogin', false)
      //       threadedLeaveWordButtonLoading.value = false
      //     }
      //   })
      //   .catch(() => {
      //     threadedLeaveWordButtonLoading.value = false
      //   })
    }
    return {
      leaveWordForm,
      leaveWordButtonLoading,
      insertLeaveWord,
      leaveWordInfo,
      leaveWordTotal,
      pageSize,
      changePage,
      threadedLeaveWordId,
      threadedLeaveWordFrom,
      clickThreadedLeaveWord,
      insertThreadedLeaveWord,
      selectLeaveWord
    }
  }
}
</script>

<style>
</style>
<style src="../css/messageBoard.css" scoped>
</style>
