<template>
  <div>
    <!-- 评论/留言信息展示 -->
    <div
      class="message_info"
      :style="{'margin-left':style.margin_left,'margin-top':style.margin_top}"
    >
      <div class="avatar_photo_background">
        <!-- 头像图片 -->
        <img
          :src="'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/'+messageDate.user_avatar"
          class="avatar_photo"
        />
      </div>
      <div class="message_info_left">
        <div>
          <span class="user_nickname">{{messageDate.user_nickname}}</span>
          <!-- 回复子留言增加@用户昵称 -->
          <span v-if="messageDate.second_father_user_id != null">:回复<span
              class="user_nickname">@{{messageDate.second_father_user_nickname}}</span></span>
          <span>:{{messageDate.message_contents || messageDate.comment_content}}</span>
        </div>
        <div class="message_info_left_bottom">
          {{messageDate.create_time_toDate}}&nbsp;&nbsp;
          <span v-if="messageDate.address_city != null">来自{{messageDate.address_city}}</span>
          <i
            class="fa fa-commenting-o reply_icon"
            @click="activateInput(messageDate.id)"
          ></i>
        </div>
      </div>
    </div>
    <!-- 回复输入框 -->
    <div
      v-if="$store.state.activateInputId == messageDate.id"
      class="message_reply"
      :style="{'margin-left':style.margin_left}"
    >
      <el-input
        v-model="replyContent"
        :placeholder="'回复@'+messageDate.user_nickname"
        class="message_reply_input"
      />
      <el-button
        v-if="$store.state.isLogin"
        type="primary"
        @click="submitData()"
        :loading="replyButtonLoading"
        class="message_reply_button"
      >回复</el-button>
      <!-- 未登录提示 -->
      <el-popover
        placement="top"
        v-else
      >
        <template #reference>
          <el-button
            type="primary"
            class="message_reply_button"
          >回复</el-button>
        </template>
        <template #default>
          需要登录才能回复哦。^_~
        </template>
      </el-popover>
    </div>
  </div>
</template>

<script>
// import { ref } from '_vue@3.2.31@vue'
import { ref } from '@vue/runtime-core'
import store from '../store/index'
import { ElMessage } from 'element-plus'
import { setCookie } from '../tools/cookie.ts'
export default {
  props: {
    messageDate: {
      type: Object
    },
    style: {
      type: Object
    },
    father_id: {
      type: Number
    },
    scene: {
      type: String
    },
    submitDataFunction: {
      type: Function
    }
  },
  emit: ['getData'],

  setup(props, context) {
    // 激活回复输入框
    const activateInput = (id) => {
      store.commit('updateActivateInputId', id)
    }
    // 回复内容
    const replyContent = ref(null)
    // 回复按钮等待状态
    const replyButtonLoading = ref(false)
    // 提交回复内容
    const submitData = () => {
      if (replyContent.value === null || replyContent.value === '') {
        ElMessage({
          showClose: true,
          message: '你忘记填写内容了！',
          type: 'warning'
        })
        return
      }
      replyButtonLoading.value = true
      props
        .submitDataFunction(
          props.father_id,
          props.scene === 'second' ? store.state.activateInputId : 0,
          replyContent.value
        )
        .then((response) => {
          if (response.code === 0) {
            // 重新获取留言or评论
            context.emit('getData')
            replyContent.value = ''
            replyButtonLoading.value = false
            ElMessage({
              showClose: true,
              message: response.msg,
              type: 'success'
            })
            store.commit('updateActivateInputId', 0)
          } else if (response.code === -1) {
            ElMessage({
              showClose: true,
              message: response.msg,
              type: 'warning'
            })
            // 清空cookie
            setCookie('blogName', '', -1)
            setCookie('avatar', '', -1)
            setCookie('is_login', '', -1)
            // 更新vuex的状态
            store.commit('updateIsLogin', false)
            replyButtonLoading.value = false
          }
        })
    }
    return {
      activateInput,
      submitData,
      replyContent,
      replyButtonLoading
    }
  }
}
</script>

<style scoped>
/* 内容展示 */
.message_info {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}

.avatar_photo {
  width: 40px;
}

.avatar_photo_background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: #f4f4f4;
  margin-right: 8px;
  border-radius: 10px;
}

.message_info_left {
  width: 100%;
  font-size: 14px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user_nickname {
  color: #eb7350;
}

.message_info_left_bottom {
  color: #808080;
  font-family: Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    'WenQuanYi Micro Hei', sans-serif;
  font-size: 12px;
}

.reply_icon {
  margin-left: 16px;
  cursor: pointer;
  padding: 4px;
}

.reply_icon:hover {
  color: #eb7350;
  background-color: rgba(245, 178, 42, 0.2);
  border-radius: 50%;
}
/* 回复输入框 */
.message_reply {
  display: flex;
  margin-top: 4px;
}

.message_reply_input {
  margin-left: 50px;
}

.message_reply_button {
  width: 88px;
  margin-left: 8px;
}
</style>
