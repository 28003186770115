<template>
  <div class="background">
    <div class="container">
      <div class="info">
        <slot>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
/* reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.background {
  background-color: #f6f6f6;
}

.container {
  /* 目的可以水平居中 */
  margin: 0px auto;
  /* 定义元素的最大宽度 */
  max-width: 1200px;
  padding: 16px 0;
}

.info {
  margin: 16px 0px;
  /* background-color: #ffffff; */
  padding: 0px 16px;
}
</style>
